import React, { lazy, Suspense, useEffect } from 'react';

import {
  aiSettings,
	assetsUrl,
	contributorsUrl,
	dashboardUrl,
	generateSetlist,
	loginUrl,
	pipelineJobsUrl,
	reelsUrl,
	songsUrl,
	songWeight,
	songWeights,
	videoCollectionsUrl,
	videosUrl,
} from '@/config/urls';
import { AuthProvider } from '@/providers/AuthProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router';

import AppLoading from './components/AppLoading';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Layout } from './components/Layout';
import Assets from './pages/Assets/Assets';
import Contributors from './pages/Contributors/Contributors';
import Dashboard from './pages/Dashboard/Dashboard';
import LoginPage from './pages/Login/LoginPage';
import PipelineJobs from './pages/PipelineJobs/PipelineJobs';
import Songs from './pages/Songs/Songs';
import VideoCollections from './pages/VideoCollections/VideoCollections';
import Videos from './pages/Videos/Videos';
import { PermissionsProvider } from './providers/PermissionsProvider';
import Reels from './pages/Reels/Reels';
import AiSettings from './pages/AISettings/AiSettings';
import SongWeights from './pages/SongWeights/SongWeights';
import SongWeight from './pages/SongWeight/SongWeight';
import GenerateSetlist from './pages/GenerateSetlist/GenerateSetlist';

const queryClient = new QueryClient();

const AuthenticatedApp = () => {
	return (
		<PermissionsProvider>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Asaph Admin</title>
			</Helmet>
			<Routes>
				<Route
					path={dashboardUrl}
					element={
						<Layout>
							<Dashboard />
						</Layout>
					}
				/>

				<Route
					path={videoCollectionsUrl}
					element={
						<Layout>
							<VideoCollections />
						</Layout>
					}
				/>
				<Route
					path={videosUrl}
					element={
						<Layout>
							<Videos />
						</Layout>
					}
				/>
				<Route
					path={reelsUrl}
					element={
						<Layout>
							<Reels />
						</Layout>
					}
				/>
				<Route
					path={songsUrl}
					element={
						<Layout>
							<Songs />
						</Layout>
					}
				/>
				<Route
					path={contributorsUrl}
					element={
						<Layout>
							<Contributors />
						</Layout>
					}
				/>
				<Route
					path={assetsUrl}
					element={
						<Layout>
							<Assets />
						</Layout>
					}
				/>
				<Route
					path={pipelineJobsUrl}
					element={
						<Layout>
							<PipelineJobs />
						</Layout>
					}
				/>
				<Route
					path={aiSettings}
					element={
						<Layout>
							<AiSettings />
						</Layout>
					}
				/>
				<Route
					path={generateSetlist}
					element={
						<Layout>
							<GenerateSetlist />
						</Layout>
					}
				/>
				<Route
					path={songWeights}
					element={
						<Layout>
							<SongWeights />
						</Layout>
					}
				/>
				<Route
					path={songWeight}
					element={
						<Layout>
							<SongWeight />
						</Layout>
					}
				/>

				<Route path="*" element={<Navigate to={dashboardUrl} />} />
			</Routes>
		</PermissionsProvider>
	);
};

const UnAuthenticatedApp: React.FC = () => {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Asaph Admin</title>
			</Helmet>
			<Routes>
				<Route path={loginUrl} element={<LoginPage />} />
				<Route path="*" element={<Navigate to={loginUrl} />} />
			</Routes>
		</>
	);
};

const App: React.FC = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<AppLoading>
				<ErrorBoundary>
					<AuthProvider>
						{({ isAuthenticated }) =>
							isAuthenticated ? (
								<AuthenticatedApp />
							) : (
								<UnAuthenticatedApp />
							)
						}
					</AuthProvider>
				</ErrorBoundary>
			</AppLoading>
		</QueryClientProvider>
	);
};

export default App;
