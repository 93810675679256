import React, { useState } from 'react';

import FormikField from '@/components/shared/FormikField';
import { useDeleteSongMutation, useListSongsQuery } from '@/graphql';
import {
	Button,
	ButtonGroup,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spinner,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';

import CreateSongModal, {
	CreateSongInitialValues,
} from './components/CreateSongModal';
import SongCard from './components/SongCard';

const Songs = () => {
	const { data, error, isLoading, refetch } = useListSongsQuery();
	const { mutateAsync } = useDeleteSongMutation({
		onSuccess: () => {
			refetch();
		},
	});
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const [initialData, setInitialData] = useState<CreateSongInitialValues>();
	const [searchParam, setSearchParam] = useState('');
	const [withLyrics, setWithLyrics] = useState(false);

	if (isLoading) {
		return <Spinner />;
	}

	if (error || !data) {
		throw error;
	}

	return (
		<>
			<CreateSongModal
				isOpen={isOpen}
				onClose={onClose}
				onSuccess={refetch}
				initialData={initialData}
			/>
			<Button
				display="block"
				mx="auto"
				my="50px"
				flexShrink={0}
				onClick={() => {
					setInitialData(undefined);
					onOpen();
				}}
			>
				Create song
			</Button>
			<Flex gap={5}>
				<FormControl flexBasis={1} flexGrow={1} flexShrink={1}>
					<FormLabel>Search param</FormLabel>
					<Input
						placeholder="Artist, Name, Lyrics"
						type={'text'}
						onChange={(e) => setSearchParam(e.currentTarget.value)}
						value={searchParam}
					/>
				</FormControl>

				<FormControl flexGrow={0} flexShrink={1} flexBasis='100px'>
					<FormLabel>With Lyrics</FormLabel>
					<Checkbox
						size="lg"
						onChange={(e) => setWithLyrics(e.target.checked)}
						isChecked={withLyrics}
					/>
				</FormControl>
			</Flex>
			<Flex gap="20px" mt="30px" alignItems="stretch" flexWrap="wrap">
				{data.listSongs.map((song) => {
					if (!song) {
						return null;
					}
					const songLyrics = (song.lyrics || '').toLowerCase();
					if (withLyrics && songLyrics.length < 1) {
						return null;
					}
					return song.name
						.toLowerCase()
						.search(searchParam.toLowerCase()) > -1 ||
						song.artist
							.toLowerCase()
							.search(searchParam.toLowerCase()) > -1 ||
						songLyrics.search(searchParam.toLowerCase()) > -1 ? (
						<SongCard
							flexShrink={0}
							key={song.id}
							w="240px"
							id={song.id}
							songThemes={{
								emotionTags: song.emotionTags,
								energyTags: song.energyTags,
								gospelTags: song.gospelTags,
								songTypeTags: song.songTypeTags,
							}}
							name={song.name}
							artworkUrl={song.artworkUrl}
							artist={song.artist}
							footer={
								<ButtonGroup spacing="2" w="100%">
									<Button
										variant="outline"
										onClick={() => {
											setInitialData(song);
											onOpen();
										}}
									>
										Edit
									</Button>
									<Popover>
										{({ onClose }) => (
											<>
												<PopoverTrigger>
													<Button
														variant="ghost"
														colorScheme="red"
													>
														Delete
													</Button>
												</PopoverTrigger>
												<PopoverContent>
													<PopoverArrow />
													<PopoverHeader>
														Confirmation!
													</PopoverHeader>
													<PopoverBody>
														<ButtonGroup
															spacing="2"
															w="100%"
														>
															<Button
																variant="solid"
																colorScheme="red"
																onClick={async () => {
																	try {
																		await mutateAsync(
																			{
																				songId: song.id,
																			},
																		);

																		toast({
																			title: `Song ${song.name} deleted!`,
																		});
																		onClose();
																	} catch (err) {
																		console.error(
																			err,
																		);
																		toast({
																			title: 'Error deleting song!',
																			status: 'error',
																			description:
																				err instanceof
																				Error
																					? err.message
																					: 'Unkown error, check console',
																		});
																	}
																}}
															>
																Yes
															</Button>
															<Button
																variant="ghost"
																onClick={
																	onClose
																}
															>
																No
															</Button>
														</ButtonGroup>
													</PopoverBody>
												</PopoverContent>
											</>
										)}
									</Popover>
								</ButtonGroup>
							}
						/>
					) : null;
				})}
			</Flex>
		</>
	);
};

export default Songs;
