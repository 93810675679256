import React from 'react';

import {
	Card,
	CardBody,
	CardFooter,
	CardProps,
	Divider,
	Image,
	Tag,
	Text,
	Wrap,
} from '@chakra-ui/react';

type SongCardProps = CardProps & {
	id: string;
	name: string;
	artworkUrl?: string | null;
	artist: string;
	footer?: React.ReactNode;
	songThemes?: {
		emotionTags: string[];
		energyTags: string[];
		gospelTags: string[];
		songTypeTags: string[];
	};
};

const SongCard: React.FC<SongCardProps> = ({
	id,
	name,
	artworkUrl,
	artist,
	footer,
	songThemes,
	...props
}) => {
	return (
		<Card {...props} display="inline-flex">
			<CardBody position="relative">
				<Image
					src={artworkUrl ?? ''}
					fallbackSrc="https://via.placeholder.com/200"
				/>
				<Text mt="10px" as="b" noOfLines={1}>
					{name}
				</Text>
				<Text>{artist}</Text>
				{songThemes && (
					<Wrap mt={2}>
						{[
							...songThemes.energyTags,
							...songThemes.emotionTags,
							...songThemes.gospelTags,
							...songThemes.songTypeTags,
						].map((t) => (
							<Tag size="sm">{t}</Tag>
						))}
					</Wrap>
				)}
			</CardBody>
			{footer && (
				<>
					<Divider borderColor="brand.200" />
					<CardFooter>{footer}</CardFooter>
				</>
			)}
		</Card>
	);
};

export default SongCard;
