import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { useFetchData } from './codegen-fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Asset = {
  __typename?: 'Asset';
  key: Scalars['String'];
  lastModified: Scalars['String'];
  url: Scalars['String'];
};

export type Chat = {
  __typename?: 'Chat';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  messages?: Maybe<MessageResults>;
  name?: Maybe<Scalars['String']>;
  stat?: Maybe<ChatStat>;
  type: Scalars['String'];
  userIds: Array<Scalars['String']>;
  users?: Maybe<Array<User>>;
};


export type ChatMessagesArgs = {
  input: PaginationInput;
};

export type ChatStat = {
  __typename?: 'ChatStat';
  lastSeenAt: Scalars['String'];
};

export enum ChatType {
  Dm = 'DM',
  Group = 'GROUP',
  Video = 'VIDEO'
}

export type Contributor = {
  __typename?: 'Contributor';
  description: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  role: Scalars['String'];
  videoIds: Array<Scalars['String']>;
  videos?: Maybe<Array<Video>>;
};

export type ContributorInput = {
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  role: Scalars['String'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  id: Scalars['ID'];
  videoCollectionIds: Array<Scalars['String']>;
  videoCollections?: Maybe<Array<VideoCollection>>;
};

export type DashboardInput = {
  videoCollectionIds: Array<Scalars['String']>;
};

export type GenerateSetlistDefinitionInput = {
  global?: InputMaybe<GenerateSetlistRequestInput>;
  positions: Array<GenerateSetlistRequestInput>;
};

export type GenerateSetlistRequestInput = {
  emotionTags?: InputMaybe<Array<Scalars['String']>>;
  energyTags?: InputMaybe<Array<Scalars['String']>>;
  gospelTags?: InputMaybe<Array<Scalars['String']>>;
  rotationStatus?: InputMaybe<Array<Scalars['String']>>;
  songTypeTags?: InputMaybe<Array<Scalars['String']>>;
};

export type GeneratedSetlist = {
  __typename?: 'GeneratedSetlist';
  positions: Array<Maybe<TeamSong>>;
};

export type Invite = {
  __typename?: 'Invite';
  email: Scalars['String'];
  id: Scalars['ID'];
};

export type Membership = {
  __typename?: 'Membership';
  roles: Array<Scalars['String']>;
  user: User;
  userId: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  content: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['String']>;
  reactions: Array<Reaction>;
  type: MessageType;
  user?: Maybe<User>;
  userId: Scalars['String'];
};

export type MessageInput = {
  content: Scalars['String'];
  id: Scalars['ID'];
  metadata?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MessageType>;
};

export type MessageResults = {
  __typename?: 'MessageResults';
  count: Scalars['Int'];
  items: Array<Message>;
  totalCount: Scalars['Int'];
};

export enum MessageType {
  Setlist = 'SETLIST',
  Songlist = 'SONGLIST',
  Status = 'STATUS',
  Text = 'TEXT'
}

export type Mutation = {
  __typename?: 'Mutation';
  addSongsToMyTeam: Array<TeamSong>;
  createContributor: Contributor;
  createDashboard: Dashboard;
  createInvite?: Maybe<Invite>;
  createMessageReaction: Reaction;
  createReel: Reel;
  createSetlist: Setlist;
  createSetlistFeedbackSubmission: SetlistFeedbackSubmission;
  createSong: Song;
  createSongWeight: SongWeight;
  createTeamService: Service;
  createTeamSong: TeamSong;
  createVideo: Video;
  createVideoCollection: VideoCollection;
  deleteContributor: Contributor;
  deleteInvite?: Maybe<Invite>;
  deleteMyUser: User;
  deleteReel: Reel;
  deleteSetlist: Setlist;
  deleteSong: Song;
  deleteSongWeight: SongWeight;
  deleteTeamService: Service;
  deleteTeamSong: TeamSong;
  deleteVideo: Video;
  deleteVideoCollection: VideoCollection;
  demoteUserToTeamMember: Scalars['Boolean'];
  generateSetlist: GeneratedSetlist;
  getAllWeightedTeamSongs: Array<WeightedTeamSong>;
  getChargebeePortalSession?: Maybe<Scalars['String']>;
  getChat: Chat;
  getChatReactions: Array<Reaction>;
  getVideoChat: VideoChat;
  leaveChat: Chat;
  promoteUserToTeamAdmin: Scalars['Boolean'];
  removeUserFromTeam: Scalars['Boolean'];
  renameChat: Chat;
  resendInvite?: Maybe<Invite>;
  searchSongs: SongResults;
  sendChatMessage: Scalars['Boolean'];
  sendCode: Scalars['Boolean'];
  sendVideoChatMessage: Scalars['Boolean'];
  testNotifications?: Maybe<Scalars['Boolean']>;
  updateChat: Chat;
  updateContributor: Contributor;
  updateDashboard: Dashboard;
  updateLastSeenAt: User;
  updateMyTeam?: Maybe<Team>;
  updateMyUser: User;
  updateReel: Reel;
  updateSetlist: Setlist;
  updateSong: Song;
  updateSongWeight: SongWeight;
  updateTeamService: Service;
  updateTeamSong: TeamSong;
  updateVideo: Video;
  updateVideoCollection: VideoCollection;
  upsertChat: Chat;
  upsertChatStat: ChatStat;
  upsertReelStat: ReelStat;
  upsertVideoCollectionStat: Scalars['Boolean'];
  upsertVideoStat: VideoStat;
};


export type MutationAddSongsToMyTeamArgs = {
  songIds: Array<Scalars['String']>;
};


export type MutationCreateContributorArgs = {
  input: ContributorInput;
};


export type MutationCreateDashboardArgs = {
  input: DashboardInput;
};


export type MutationCreateInviteArgs = {
  email: Scalars['String'];
  shouldBeAdmin: Scalars['Boolean'];
};


export type MutationCreateMessageReactionArgs = {
  emojiCode: Scalars['String'];
  messageId: Scalars['String'];
};


export type MutationCreateReelArgs = {
  input: ReelInput;
};


export type MutationCreateSetlistArgs = {
  input: SetlistInput;
};


export type MutationCreateSetlistFeedbackSubmissionArgs = {
  input: SetlistFeedbackSubmissionInput;
};


export type MutationCreateSongArgs = {
  input: SongInput;
};


export type MutationCreateSongWeightArgs = {
  input: SongWeightInput;
};


export type MutationCreateTeamServiceArgs = {
  input: ServiceInput;
};


export type MutationCreateTeamSongArgs = {
  input: TeamSongInput;
};


export type MutationCreateVideoArgs = {
  input: VideoInput;
};


export type MutationCreateVideoCollectionArgs = {
  input: VideoCollectionInput;
};


export type MutationDeleteContributorArgs = {
  contributorId: Scalars['String'];
};


export type MutationDeleteInviteArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMyUserArgs = {
  code: Scalars['String'];
};


export type MutationDeleteReelArgs = {
  reelId: Scalars['String'];
};


export type MutationDeleteSetlistArgs = {
  setlistId: Scalars['String'];
};


export type MutationDeleteSongArgs = {
  songId: Scalars['String'];
};


export type MutationDeleteSongWeightArgs = {
  weightId: Scalars['String'];
};


export type MutationDeleteTeamServiceArgs = {
  serviceId: Scalars['String'];
};


export type MutationDeleteTeamSongArgs = {
  teamSongId: Scalars['String'];
};


export type MutationDeleteVideoArgs = {
  videoId: Scalars['String'];
};


export type MutationDeleteVideoCollectionArgs = {
  videoCollectionId: Scalars['String'];
};


export type MutationDemoteUserToTeamMemberArgs = {
  userId: Scalars['String'];
};


export type MutationGenerateSetlistArgs = {
  request: GenerateSetlistDefinitionInput;
  teamId: Scalars['String'];
};


export type MutationGetAllWeightedTeamSongsArgs = {
  request: GenerateSetlistRequestInput;
  teamId: Scalars['String'];
};


export type MutationGetChatArgs = {
  chatId: Scalars['String'];
};


export type MutationGetChatReactionsArgs = {
  chatId: Scalars['String'];
  fromDate?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
};


export type MutationGetVideoChatArgs = {
  videoId: Scalars['String'];
};


export type MutationLeaveChatArgs = {
  chatId: Scalars['String'];
};


export type MutationPromoteUserToTeamAdminArgs = {
  userId: Scalars['String'];
};


export type MutationRemoveUserFromTeamArgs = {
  userId: Scalars['String'];
};


export type MutationRenameChatArgs = {
  chatId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationResendInviteArgs = {
  id: Scalars['String'];
};


export type MutationSearchSongsArgs = {
  excludeMyTeamSongs?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<SearchSongsFilters>;
  input: PaginationInput;
  term?: InputMaybe<Scalars['String']>;
};


export type MutationSendChatMessageArgs = {
  chatId: Scalars['String'];
  input: MessageInput;
};


export type MutationSendVideoChatMessageArgs = {
  input: MessageInput;
  videoId: Scalars['String'];
};


export type MutationTestNotificationsArgs = {
  input?: InputMaybe<TestNotificationsInput>;
};


export type MutationUpdateChatArgs = {
  chatId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};


export type MutationUpdateContributorArgs = {
  contributorId: Scalars['String'];
  input: ContributorInput;
};


export type MutationUpdateDashboardArgs = {
  input: DashboardInput;
};


export type MutationUpdateMyTeamArgs = {
  input: TeamInput;
};


export type MutationUpdateMyUserArgs = {
  input: UserInput;
};


export type MutationUpdateReelArgs = {
  input: ReelInput;
  reelId: Scalars['String'];
};


export type MutationUpdateSetlistArgs = {
  input: SetlistInput;
  setlistId: Scalars['String'];
};


export type MutationUpdateSongArgs = {
  input: SongInput;
  songId: Scalars['String'];
};


export type MutationUpdateSongWeightArgs = {
  input: SongWeightInput;
  weightId: Scalars['String'];
};


export type MutationUpdateTeamServiceArgs = {
  input: ServiceInput;
  serviceId: Scalars['String'];
};


export type MutationUpdateTeamSongArgs = {
  input: TeamSongInput;
  teamSongId: Scalars['String'];
};


export type MutationUpdateVideoArgs = {
  input: VideoInput;
  videoId: Scalars['String'];
};


export type MutationUpdateVideoCollectionArgs = {
  input: VideoCollectionInput;
  videoCollectionId: Scalars['String'];
};


export type MutationUpsertChatArgs = {
  userIds: Array<Scalars['String']>;
};


export type MutationUpsertChatStatArgs = {
  chatId: Scalars['String'];
};


export type MutationUpsertReelStatArgs = {
  input: ReelStatInput;
  reelId: Scalars['String'];
};


export type MutationUpsertVideoCollectionStatArgs = {
  input: VideoCollectionStatInput;
  videoCollectionId: Scalars['String'];
};


export type MutationUpsertVideoStatArgs = {
  input: VideoStatInput;
  videoId: Scalars['String'];
};

export type NotificationPreferences = {
  __typename?: 'NotificationPreferences';
  chat: Scalars['Boolean'];
  groupChat: Scalars['Boolean'];
  videoChat: Scalars['Boolean'];
  videoChatActiveOnly: Scalars['Boolean'];
};

export type NotificationPreferencesInput = {
  chat: Scalars['Boolean'];
  groupChat: Scalars['Boolean'];
  videoChat: Scalars['Boolean'];
  videoChatActiveOnly: Scalars['Boolean'];
};

export enum NotificationResourceType {
  Dm = 'DM',
  GroupChat = 'GROUP_CHAT',
  VideoChat = 'VIDEO_CHAT'
}

export type PaginationInput = {
  afterDate?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<SortDirection>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export enum Permissions {
  ChargebeeSessionGet = 'ChargebeeSessionGet',
  ChargebeeSubscriptionCheck = 'ChargebeeSubscriptionCheck',
  ChatCreate = 'ChatCreate',
  ChatUpdate = 'ChatUpdate',
  ContributorCreate = 'ContributorCreate',
  ContributorDelete = 'ContributorDelete',
  ContributorUpdate = 'ContributorUpdate',
  DashboardCreate = 'DashboardCreate',
  DashboardGet = 'DashboardGet',
  DashboardUpdate = 'DashboardUpdate',
  DevFeatureRead = 'DevFeatureRead',
  DevtoolsRead = 'DevtoolsRead',
  InviteCreate = 'InviteCreate',
  InviteDelete = 'InviteDelete',
  MembershipCreate = 'MembershipCreate',
  MembershipDelete = 'MembershipDelete',
  MembershipUpdate = 'MembershipUpdate',
  ReelCreate = 'ReelCreate',
  ReelDelete = 'ReelDelete',
  ReelFeedGet = 'ReelFeedGet',
  ReelGet = 'ReelGet',
  ReelList = 'ReelList',
  ReelStatCreate = 'ReelStatCreate',
  ReelStatUpdate = 'ReelStatUpdate',
  ReelUpdate = 'ReelUpdate',
  ServiceCreate = 'ServiceCreate',
  ServiceDelete = 'ServiceDelete',
  ServiceGet = 'ServiceGet',
  ServiceList = 'ServiceList',
  ServiceUpdate = 'ServiceUpdate',
  SetlistCreate = 'SetlistCreate',
  SetlistDelete = 'SetlistDelete',
  SetlistGet = 'SetlistGet',
  SetlistList = 'SetlistList',
  SetlistUpdate = 'SetlistUpdate',
  SongCreate = 'SongCreate',
  SongDelete = 'SongDelete',
  SongList = 'SongList',
  SongUpdate = 'SongUpdate',
  SongWeightCreate = 'SongWeightCreate',
  SongWeightDelete = 'SongWeightDelete',
  SongWeightGet = 'SongWeightGet',
  SongWeightList = 'SongWeightList',
  SongWeightUpdate = 'SongWeightUpdate',
  SonglistUpdate = 'SonglistUpdate',
  SubscriptionCreate = 'SubscriptionCreate',
  SubscriptionUpdate = 'SubscriptionUpdate',
  TeamDelete = 'TeamDelete',
  TeamList = 'TeamList',
  TeamSongCreate = 'TeamSongCreate',
  TeamSongDelete = 'TeamSongDelete',
  TeamSongList = 'TeamSongList',
  TeamSongUpdate = 'TeamSongUpdate',
  TeamUpdate = 'TeamUpdate',
  VideoChatCreate = 'VideoChatCreate',
  VideoChatUpdate = 'VideoChatUpdate',
  VideoCollectionCreate = 'VideoCollectionCreate',
  VideoCollectionDelete = 'VideoCollectionDelete',
  VideoCollectionGet = 'VideoCollectionGet',
  VideoCollectionList = 'VideoCollectionList',
  VideoCollectionUpdate = 'VideoCollectionUpdate',
  VideoCreate = 'VideoCreate',
  VideoDelete = 'VideoDelete',
  VideoGet = 'VideoGet',
  VideoList = 'VideoList',
  VideoUpdate = 'VideoUpdate',
  WeightedTeamSongList = 'WeightedTeamSongList'
}

export type PipelineJob = {
  __typename?: 'PipelineJob';
  dashUrl: Scalars['String'];
  duration: Scalars['Int'];
  endTime?: Maybe<Scalars['String']>;
  frameCaptureHeight: Scalars['Int'];
  frameCaptureWidth: Scalars['Int'];
  guid: Scalars['ID'];
  hlsUrl: Scalars['String'];
  srcVideo: Scalars['String'];
  startTime: Scalars['String'];
  thumbNailsUrls: Scalars['String'];
  workflowStatus: Scalars['String'];
};

export type PipelineJobsFilter = {
  srcVideo?: InputMaybe<Scalars['String']>;
};

export type QuartileData = {
  __typename?: 'QuartileData';
  q1: Scalars['Float'];
  q2: Scalars['Float'];
  q3: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  checkAndSetChargebeeSubscription: Scalars['Boolean'];
  getChat: Chat;
  getContributor: Contributor;
  getDashboard: Dashboard;
  getMyPermissions: Array<Scalars['String']>;
  getMyTeam: Team;
  getMyUser: User;
  getPipelineJob: PipelineJob;
  getReel: Reel;
  getReelFeed: Array<Reel>;
  getSetlist: Setlist;
  getSong: Song;
  getSongWeight: SongWeight;
  getTeamService: Service;
  getTeamSetlist: Setlist;
  getTeamSong: TeamSong;
  getTeamSongs: Array<Maybe<TeamSong>>;
  getVideo: Video;
  getVideoChat: VideoChat;
  getVideoCollection: VideoCollection;
  listAssets: Array<Asset>;
  listContributors: Array<Maybe<Contributor>>;
  listInvites?: Maybe<Array<Maybe<Invite>>>;
  listMyChats: Array<Chat>;
  listMySetlistFeedbackRequests: Array<Setlist>;
  listMySetlists: Array<Setlist>;
  listMyTeamSetlists: Array<Setlist>;
  listMyTeamSongs: Array<TeamSong>;
  listPipelineJobs: Array<Maybe<PipelineJob>>;
  listReels: Array<Reel>;
  listSetlistsByChatId: Array<Setlist>;
  listSongWeights: Array<SongWeight>;
  listSongs: Array<Song>;
  listTeamServices: Array<Service>;
  listTeamSongs: Array<TeamSong>;
  listTeamVideoChats: Array<VideoChat>;
  listTeams: Array<Team>;
  listUsers?: Maybe<Array<Maybe<User>>>;
  listVideoCollections: Array<VideoCollection>;
  listVideos: Array<Maybe<Video>>;
};


export type QueryGetChatArgs = {
  chatId: Scalars['String'];
};


export type QueryGetContributorArgs = {
  id: Scalars['String'];
};


export type QueryGetPipelineJobArgs = {
  guid: Scalars['String'];
};


export type QueryGetReelArgs = {
  id: Scalars['String'];
};


export type QueryGetSetlistArgs = {
  setlistId: Scalars['String'];
};


export type QueryGetSongArgs = {
  songId: Scalars['String'];
};


export type QueryGetSongWeightArgs = {
  weightId: Scalars['String'];
};


export type QueryGetTeamServiceArgs = {
  serviceId: Scalars['String'];
};


export type QueryGetTeamSetlistArgs = {
  setlistId: Scalars['String'];
};


export type QueryGetTeamSongArgs = {
  teamSongId: Scalars['String'];
};


export type QueryGetTeamSongsArgs = {
  teamSongIds: Array<Scalars['String']>;
};


export type QueryGetVideoArgs = {
  id: Scalars['String'];
};


export type QueryGetVideoChatArgs = {
  videoId: Scalars['String'];
};


export type QueryGetVideoCollectionArgs = {
  videoCollectionId: Scalars['String'];
};


export type QueryListMyChatsArgs = {
  unreadOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryListPipelineJobsArgs = {
  filter: PipelineJobsFilter;
};


export type QueryListSetlistsByChatIdArgs = {
  chatId: Scalars['String'];
  input: PaginationInput;
};


export type QueryListTeamSongsArgs = {
  teamId: Scalars['String'];
};


export type QueryListTeamVideoChatsArgs = {
  unreadOnly?: InputMaybe<Scalars['Boolean']>;
};

export type Reaction = {
  __typename?: 'Reaction';
  createdAt: Scalars['String'];
  emojiCode: Scalars['String'];
  id: Scalars['ID'];
  messageId: Scalars['ID'];
  user?: Maybe<User>;
  userId: Scalars['String'];
};

export type Reel = {
  __typename?: 'Reel';
  id: Scalars['ID'];
  name: Scalars['String'];
  posterUrl: Scalars['String'];
  stat?: Maybe<ReelStat>;
  url: Scalars['String'];
  video?: Maybe<Video>;
  videoId: Scalars['ID'];
};

export type ReelInput = {
  name: Scalars['String'];
  posterUrl: Scalars['String'];
  url: Scalars['String'];
  videoId: Scalars['ID'];
};

export type ReelStat = {
  __typename?: 'ReelStat';
  liked: Scalars['Boolean'];
};

export type ReelStatInput = {
  liked: Scalars['Boolean'];
};

export type SearchSongsFilters = {
  tags?: InputMaybe<Array<Scalars['String']>>;
  tagsByCategory?: InputMaybe<TagsByCategoryFilter>;
  term?: InputMaybe<Scalars['String']>;
};

export type Service = {
  __typename?: 'Service';
  createdAt: Scalars['String'];
  day: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ServiceInput = {
  day: Scalars['Int'];
  name: Scalars['String'];
  startAt: Scalars['String'];
};

export type Setlist = {
  __typename?: 'Setlist';
  chat?: Maybe<Chat>;
  chatId: Scalars['ID'];
  createdAt: Scalars['String'];
  draft: Scalars['Boolean'];
  feedback?: Maybe<SetlistFeedback>;
  id: Scalars['ID'];
  serviceId?: Maybe<Scalars['ID']>;
  startAt: Scalars['String'];
  teamSongIds: Array<Scalars['ID']>;
  teamSongs: Array<Maybe<TeamSong>>;
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type SetlistFeedback = {
  __typename?: 'SetlistFeedback';
  congregationalScoreQuartiles: QuartileData;
  personalScoreQuartiles: QuartileData;
  setlistId: Scalars['String'];
  teamScoreQuartiles: QuartileData;
  teamSongFeedbackReports: Array<TeamSongFeedbackReport>;
};

export type SetlistFeedbackSubmission = {
  __typename?: 'SetlistFeedbackSubmission';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  setlistId: Scalars['String'];
  teamSongFeedbackSubmissionIds: Array<Scalars['String']>;
  teamSongFeedbackSubmissions: Array<TeamSongFeedbackSubmission>;
};

export type SetlistFeedbackSubmissionInput = {
  setlistId: Scalars['String'];
  teamSongFeedbackSubmissions: Array<TeamSongFeedbackSubmissionInput>;
};

export type SetlistInput = {
  chatId: Scalars['ID'];
  draft?: InputMaybe<Scalars['Boolean']>;
  serviceId?: InputMaybe<Scalars['String']>;
  startAt: Scalars['String'];
  teamSongIds: Array<Scalars['ID']>;
};

export type Song = {
  __typename?: 'Song';
  appleMusicId?: Maybe<Scalars['String']>;
  artist: Scalars['String'];
  artworkUrl?: Maybe<Scalars['String']>;
  ccli?: Maybe<Scalars['Int']>;
  emotionTags: Array<Scalars['String']>;
  energyTags: Array<Scalars['String']>;
  gospelTags: Array<Scalars['String']>;
  id: Scalars['ID'];
  lyrics?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  recommendedKey?: Maybe<Scalars['String']>;
  recordedKey?: Maybe<Scalars['String']>;
  songTypeTags: Array<Scalars['String']>;
  spotifyId?: Maybe<Scalars['String']>;
  youtubeId?: Maybe<Scalars['String']>;
};

export type SongInput = {
  appleMusicId?: InputMaybe<Scalars['String']>;
  artist: Scalars['String'];
  artworkUrl?: InputMaybe<Scalars['String']>;
  ccli?: InputMaybe<Scalars['Int']>;
  emotionTags?: InputMaybe<Array<Scalars['String']>>;
  energyTags?: InputMaybe<Array<Scalars['String']>>;
  gospelTags?: InputMaybe<Array<Scalars['String']>>;
  lyrics?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recommendedKey?: InputMaybe<Scalars['String']>;
  recordedKey?: InputMaybe<Scalars['String']>;
  songTypeTags?: InputMaybe<Array<Scalars['String']>>;
  spotifyId?: InputMaybe<Scalars['String']>;
  youtubeId?: InputMaybe<Scalars['String']>;
};

export type SongResults = {
  __typename?: 'SongResults';
  count: Scalars['Int'];
  items: Array<Song>;
  totalCount: Scalars['Int'];
};

export type SongWeight = {
  __typename?: 'SongWeight';
  description?: Maybe<Scalars['String']>;
  function: Scalars['String'];
  highValue: Scalars['Int'];
  id: Scalars['ID'];
  key: Scalars['String'];
  lowValue: Scalars['Int'];
  name: Scalars['String'];
};

export type SongWeightInput = {
  description?: InputMaybe<Scalars['String']>;
  function: Scalars['String'];
  highValue: Scalars['Int'];
  key: Scalars['String'];
  lowValue: Scalars['Int'];
  name: Scalars['String'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Subscription = {
  __typename?: 'Subscription';
  teamAction?: Maybe<TeamAction>;
};

export type TagsByCategoryFilter = {
  emotionTags: Array<Scalars['String']>;
  energyTags: Array<Scalars['String']>;
  gospelTags: Array<Scalars['String']>;
  songTypeTags: Array<Scalars['String']>;
};

export type Team = {
  __typename?: 'Team';
  chartRepositoryUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviteLinkCode: Scalars['String'];
  invites?: Maybe<Array<Maybe<Invite>>>;
  members: Array<Membership>;
  name: Scalars['String'];
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus: Scalars['Boolean'];
};

export type TeamAction = {
  __typename?: 'TeamAction';
  action: TeamActionActions;
};

export enum TeamActionActions {
  ChatChanged = 'CHAT_CHANGED',
  ServiceChanged = 'SERVICE_CHANGED',
  SetlistsChanged = 'SETLISTS_CHANGED',
  TeamChanged = 'TEAM_CHANGED',
  TeamSongsChanged = 'TEAM_SONGS_CHANGED',
  VideoChatChanged = 'VIDEO_CHAT_CHANGED'
}

export type TeamInput = {
  chartRepositoryUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type TeamSong = {
  __typename?: 'TeamSong';
  appleMusicId?: Maybe<Scalars['String']>;
  artist: Scalars['String'];
  artworkUrl?: Maybe<Scalars['String']>;
  candidateSongId?: Maybe<Scalars['ID']>;
  ccli?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  defaultKey?: Maybe<Scalars['String']>;
  emotionTags: Array<Scalars['String']>;
  energyTags: Array<Scalars['String']>;
  feedback?: Maybe<TeamSongFeedback>;
  gospelTags: Array<Scalars['String']>;
  id: Scalars['ID'];
  lyrics?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originSongId?: Maybe<Scalars['ID']>;
  recommendedKey?: Maybe<Scalars['String']>;
  recordedKey?: Maybe<Scalars['String']>;
  rotationStatus?: Maybe<Scalars['String']>;
  songTypeTags: Array<Scalars['String']>;
  spotifyId?: Maybe<Scalars['String']>;
  teamId: Scalars['ID'];
  youtubeId?: Maybe<Scalars['String']>;
};

export type TeamSongFeedback = {
  __typename?: 'TeamSongFeedback';
  congregationalScoreQuartiles: QuartileData;
  personalScoreQuartiles: QuartileData;
  setlistTeamSongFeedbackReports: Array<TeamSongFeedbackReport>;
  teamScoreQuartiles: QuartileData;
  teamSongFeedbackReports: Array<TeamSongFeedbackReport>;
  teamSongFeedbackSubmissionIds: Array<Scalars['String']>;
  teamSongFeedbackSubmissions: Array<TeamSongFeedbackSubmission>;
  teamSongId: Scalars['String'];
  trend: Trend;
};

export type TeamSongFeedbackReport = {
  __typename?: 'TeamSongFeedbackReport';
  meanCongregationalScore: Scalars['Float'];
  meanPersonalScore: Scalars['Float'];
  meanTeamScore: Scalars['Float'];
  meanTotalSentimentScore: Scalars['Float'];
  setlistId: Scalars['String'];
  startAt: Scalars['String'];
  teamSongId: Scalars['String'];
};

export type TeamSongFeedbackSubmission = {
  __typename?: 'TeamSongFeedbackSubmission';
  congregationalScore: Scalars['Int'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  personalScore: Scalars['Int'];
  setlistId: Scalars['String'];
  teamScore: Scalars['Int'];
  teamSongId: Scalars['String'];
};

export type TeamSongFeedbackSubmissionInput = {
  congregationalScore: Scalars['Int'];
  personalScore: Scalars['Int'];
  teamScore: Scalars['Int'];
  teamSongId: Scalars['String'];
};

export type TeamSongInput = {
  appleMusicId?: InputMaybe<Scalars['String']>;
  artist: Scalars['String'];
  artworkUrl?: InputMaybe<Scalars['String']>;
  ccli?: InputMaybe<Scalars['Int']>;
  defaultKey?: InputMaybe<Scalars['String']>;
  emotionTags?: InputMaybe<Array<Scalars['String']>>;
  energyTags?: InputMaybe<Array<Scalars['String']>>;
  gospelTags?: InputMaybe<Array<Scalars['String']>>;
  lyrics?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recommendedKey?: InputMaybe<Scalars['String']>;
  recordedKey?: InputMaybe<Scalars['String']>;
  rotationStatus?: InputMaybe<Scalars['String']>;
  songTypeTags?: InputMaybe<Array<Scalars['String']>>;
  spotifyId?: InputMaybe<Scalars['String']>;
  youtubeId?: InputMaybe<Scalars['String']>;
};

export type TestNotificationsInput = {
  delay?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
};

export enum Trend {
  Down = 'DOWN',
  Flat = 'FLAT',
  Up = 'UP'
}

export type User = {
  __typename?: 'User';
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  lastSeenAt: Scalars['String'];
  notificationId?: Maybe<Scalars['String']>;
  notificationPreferences: NotificationPreferences;
};

export type UserInput = {
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  notificationId?: InputMaybe<Scalars['String']>;
  notificationPreferences?: InputMaybe<NotificationPreferencesInput>;
};

export type Video = {
  __typename?: 'Video';
  chat?: Maybe<VideoChat>;
  contributor?: Maybe<Contributor>;
  contributorId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  paid: Scalars['Boolean'];
  posterUrl: Scalars['String'];
  stat?: Maybe<VideoStat>;
  thumbnailUrl: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  videoCollection?: Maybe<VideoCollection>;
};

export type VideoChat = {
  __typename?: 'VideoChat';
  id: Scalars['ID'];
  messages?: Maybe<MessageResults>;
  stat?: Maybe<ChatStat>;
  type: Scalars['String'];
  video?: Maybe<Video>;
  videoId: Scalars['String'];
};


export type VideoChatMessagesArgs = {
  input: PaginationInput;
};

export type VideoCollection = {
  __typename?: 'VideoCollection';
  hex: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  onboarding: Scalars['Boolean'];
  paid: Scalars['Boolean'];
  stat?: Maybe<VideoCollectionStat>;
  videoIds: Array<Scalars['String']>;
  videos?: Maybe<Array<Video>>;
};

export type VideoCollectionInput = {
  hex: Scalars['String'];
  name: Scalars['String'];
  onboarding: Scalars['Boolean'];
  paid: Scalars['Boolean'];
  videoIds: Array<Scalars['String']>;
};

export type VideoCollectionStat = {
  __typename?: 'VideoCollectionStat';
  canBeCompleted: Scalars['Boolean'];
  completed: Scalars['Boolean'];
};

export type VideoCollectionStatInput = {
  completed: Scalars['Boolean'];
};

export type VideoInput = {
  contributorId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  name: Scalars['String'];
  paid: Scalars['Boolean'];
  posterUrl: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  url: Scalars['String'];
};

export type VideoStat = {
  __typename?: 'VideoStat';
  fullyWatched: Scalars['Boolean'];
  percentagePlayed: Scalars['Float'];
};

export type VideoStatInput = {
  percentagePlayed: Scalars['Float'];
};

export type WeightedTeamSong = {
  __typename?: 'WeightedTeamSong';
  errors: Array<Scalars['String']>;
  teamSong: TeamSong;
  teamSongId: Scalars['String'];
  weight: Scalars['Float'];
};

export type ListTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListTeamsQuery = { __typename?: 'Query', listTeams: Array<{ __typename?: 'Team', id: string, name: string }> };

export type ListTeamSongsQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type ListTeamSongsQuery = { __typename?: 'Query', listTeamSongs: Array<{ __typename?: 'TeamSong', name: string, artist: string, id: string, defaultKey?: string | null, spotifyId?: string | null, youtubeId?: string | null, originSongId?: string | null, emotionTags: Array<string>, energyTags: Array<string>, gospelTags: Array<string>, songTypeTags: Array<string>, rotationStatus?: string | null }> };

export type GetAllWeightedTeamSongsMutationVariables = Exact<{
  teamId: Scalars['String'];
  request: GenerateSetlistRequestInput;
}>;


export type GetAllWeightedTeamSongsMutation = { __typename?: 'Mutation', getAllWeightedTeamSongs: Array<{ __typename?: 'WeightedTeamSong', weight: number, teamSongId: string, errors: Array<string>, teamSong: { __typename?: 'TeamSong', name: string, artist: string, id: string, defaultKey?: string | null, spotifyId?: string | null, youtubeId?: string | null, originSongId?: string | null, emotionTags: Array<string>, energyTags: Array<string>, gospelTags: Array<string>, songTypeTags: Array<string>, rotationStatus?: string | null } }> };

export type ListAssetsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAssetsQuery = { __typename?: 'Query', listAssets: Array<{ __typename?: 'Asset', key: string, url: string }> };

export type ListContributorsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListContributorsQuery = { __typename?: 'Query', listContributors: Array<{ __typename?: 'Contributor', id: string, name: string, role: string, description: string, imageUrl?: string | null } | null> };

export type CreateContributorMutationVariables = Exact<{
  input: ContributorInput;
}>;


export type CreateContributorMutation = { __typename?: 'Mutation', createContributor: { __typename?: 'Contributor', id: string, name: string } };

export type UpdateContributorMutationVariables = Exact<{
  contributorId: Scalars['String'];
  input: ContributorInput;
}>;


export type UpdateContributorMutation = { __typename?: 'Mutation', updateContributor: { __typename?: 'Contributor', id: string, name: string } };

export type DeleteContributorMutationVariables = Exact<{
  contributorId: Scalars['String'];
}>;


export type DeleteContributorMutation = { __typename?: 'Mutation', deleteContributor: { __typename?: 'Contributor', id: string, name: string } };

export type GetDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardQuery = { __typename?: 'Query', getDashboard: { __typename?: 'Dashboard', id: string, videoCollectionIds: Array<string>, videoCollections?: Array<{ __typename?: 'VideoCollection', id: string, name: string, onboarding: boolean, videos?: Array<{ __typename?: 'Video', id: string, name: string, paid: boolean, description: string, duration: number, posterUrl: string, thumbnailUrl: string, url?: string | null, contributor?: { __typename?: 'Contributor', name: string, imageUrl?: string | null } | null }> | null }> | null } };

export type CreateDashboardMutationVariables = Exact<{
  input: DashboardInput;
}>;


export type CreateDashboardMutation = { __typename?: 'Mutation', createDashboard: { __typename?: 'Dashboard', id: string } };

export type UpdateDashboardMutationVariables = Exact<{
  input: DashboardInput;
}>;


export type UpdateDashboardMutation = { __typename?: 'Mutation', updateDashboard: { __typename?: 'Dashboard', id: string } };

export type GenerateSetlistMutationVariables = Exact<{
  teamId: Scalars['String'];
  request: GenerateSetlistDefinitionInput;
}>;


export type GenerateSetlistMutation = { __typename?: 'Mutation', generateSetlist: { __typename?: 'GeneratedSetlist', positions: Array<{ __typename?: 'TeamSong', name: string, artist: string, id: string, defaultKey?: string | null, spotifyId?: string | null, youtubeId?: string | null, originSongId?: string | null, emotionTags: Array<string>, energyTags: Array<string>, gospelTags: Array<string>, songTypeTags: Array<string>, rotationStatus?: string | null } | null> } };

export type ListPipelineJobsQueryVariables = Exact<{
  filter: PipelineJobsFilter;
}>;


export type ListPipelineJobsQuery = { __typename?: 'Query', listPipelineJobs: Array<{ __typename?: 'PipelineJob', guid: string, workflowStatus: string, frameCaptureWidth: number, frameCaptureHeight: number, duration: number, srcVideo: string, thumbNailsUrls: string, hlsUrl: string, dashUrl: string, startTime: string, endTime?: string | null } | null> };

export type GetPipelineJobQueryVariables = Exact<{
  guid: Scalars['String'];
}>;


export type GetPipelineJobQuery = { __typename?: 'Query', getPipelineJob: { __typename?: 'PipelineJob', guid: string, workflowStatus: string, frameCaptureWidth: number, frameCaptureHeight: number, duration: number, srcVideo: string, thumbNailsUrls: string, hlsUrl: string, dashUrl: string, startTime: string, endTime?: string | null } };

export type ListReelsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListReelsQuery = { __typename?: 'Query', listReels: Array<{ __typename?: 'Reel', id: string, name: string, url: string, posterUrl: string, videoId: string }> };

export type CreateReelMutationVariables = Exact<{
  input: ReelInput;
}>;


export type CreateReelMutation = { __typename?: 'Mutation', createReel: { __typename?: 'Reel', id: string, name: string } };

export type UpdateReelMutationVariables = Exact<{
  reelId: Scalars['String'];
  input: ReelInput;
}>;


export type UpdateReelMutation = { __typename?: 'Mutation', updateReel: { __typename?: 'Reel', id: string, name: string } };

export type DeleteReelMutationVariables = Exact<{
  reelId: Scalars['String'];
}>;


export type DeleteReelMutation = { __typename?: 'Mutation', deleteReel: { __typename?: 'Reel', id: string, name: string } };

export type GetSongWeightQueryVariables = Exact<{
  weightId: Scalars['String'];
}>;


export type GetSongWeightQuery = { __typename?: 'Query', getSongWeight: { __typename?: 'SongWeight', id: string, name: string, description?: string | null, function: string, key: string, lowValue: number, highValue: number } };

export type ListSongWeightsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSongWeightsQuery = { __typename?: 'Query', listSongWeights: Array<{ __typename?: 'SongWeight', id: string, name: string, description?: string | null, function: string, key: string, lowValue: number, highValue: number }> };

export type CreateSongWeightMutationVariables = Exact<{
  input: SongWeightInput;
}>;


export type CreateSongWeightMutation = { __typename?: 'Mutation', createSongWeight: { __typename?: 'SongWeight', id: string, name: string } };

export type UpdateSongWeightMutationVariables = Exact<{
  weightId: Scalars['String'];
  input: SongWeightInput;
}>;


export type UpdateSongWeightMutation = { __typename?: 'Mutation', updateSongWeight: { __typename?: 'SongWeight', id: string, name: string } };

export type DeleteSongWeightMutationVariables = Exact<{
  weightId: Scalars['String'];
}>;


export type DeleteSongWeightMutation = { __typename?: 'Mutation', deleteSongWeight: { __typename?: 'SongWeight', id: string, name: string } };

export type ListSongsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSongsQuery = { __typename?: 'Query', listSongs: Array<{ __typename?: 'Song', appleMusicId?: string | null, artist: string, artworkUrl?: string | null, ccli?: number | null, id: string, name: string, recommendedKey?: string | null, recordedKey?: string | null, spotifyId?: string | null, youtubeId?: string | null, emotionTags: Array<string>, energyTags: Array<string>, gospelTags: Array<string>, songTypeTags: Array<string>, lyrics?: string | null }> };

export type CreateSongMutationVariables = Exact<{
  input: SongInput;
}>;


export type CreateSongMutation = { __typename?: 'Mutation', createSong: { __typename?: 'Song', id: string, name: string } };

export type UpdateSongMutationVariables = Exact<{
  songId: Scalars['String'];
  input: SongInput;
}>;


export type UpdateSongMutation = { __typename?: 'Mutation', updateSong: { __typename?: 'Song', id: string, name: string } };

export type DeleteSongMutationVariables = Exact<{
  songId: Scalars['String'];
}>;


export type DeleteSongMutation = { __typename?: 'Mutation', deleteSong: { __typename?: 'Song', id: string, name: string } };

export type ListVideoCollectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListVideoCollectionsQuery = { __typename?: 'Query', listVideoCollections: Array<{ __typename?: 'VideoCollection', id: string, name: string, onboarding: boolean, videoIds: Array<string>, paid: boolean, hex: string, videos?: Array<{ __typename?: 'Video', id: string, name: string, paid: boolean, description: string, duration: number, thumbnailUrl: string, url?: string | null, contributor?: { __typename?: 'Contributor', name: string, imageUrl?: string | null } | null }> | null }> };

export type CreateVideoCollectionMutationVariables = Exact<{
  input: VideoCollectionInput;
}>;


export type CreateVideoCollectionMutation = { __typename?: 'Mutation', createVideoCollection: { __typename?: 'VideoCollection', id: string, name: string } };

export type UpdateVideoCollectionMutationVariables = Exact<{
  videoCollectionId: Scalars['String'];
  input: VideoCollectionInput;
}>;


export type UpdateVideoCollectionMutation = { __typename?: 'Mutation', updateVideoCollection: { __typename?: 'VideoCollection', id: string, name: string } };

export type DeleteVideoCollectionMutationVariables = Exact<{
  videoCollectionId: Scalars['String'];
}>;


export type DeleteVideoCollectionMutation = { __typename?: 'Mutation', deleteVideoCollection: { __typename?: 'VideoCollection', id: string } };

export type ListVideosQueryVariables = Exact<{ [key: string]: never; }>;


export type ListVideosQuery = { __typename?: 'Query', listVideos: Array<{ __typename?: 'Video', id: string, name: string, paid: boolean, description: string, duration: number, posterUrl: string, thumbnailUrl: string, url?: string | null, contributorId?: string | null, contributor?: { __typename?: 'Contributor', name: string, imageUrl?: string | null } | null } | null> };

export type CreateVideoMutationVariables = Exact<{
  input: VideoInput;
}>;


export type CreateVideoMutation = { __typename?: 'Mutation', createVideo: { __typename?: 'Video', id: string, name: string } };

export type UpdateVideoMutationVariables = Exact<{
  videoId: Scalars['String'];
  input: VideoInput;
}>;


export type UpdateVideoMutation = { __typename?: 'Mutation', updateVideo: { __typename?: 'Video', id: string, name: string } };

export type DeleteVideoMutationVariables = Exact<{
  videoId: Scalars['String'];
}>;


export type DeleteVideoMutation = { __typename?: 'Mutation', deleteVideo: { __typename?: 'Video', id: string, name: string } };

export type GetMyUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUserQuery = { __typename?: 'Query', getMyUser: { __typename?: 'User', id: string, displayName?: string | null, email: string } };

export type GetMyPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyPermissionsQuery = { __typename?: 'Query', getMyPermissions: Array<string> };


export const ListTeamsDocument = `
    query listTeams {
  listTeams {
    id
    name
  }
}
    `;
export const useListTeamsQuery = <
      TData = ListTeamsQuery,
      TError = unknown
    >(
      variables?: ListTeamsQueryVariables,
      options?: UseQueryOptions<ListTeamsQuery, TError, TData>
    ) =>
    useQuery<ListTeamsQuery, TError, TData>(
      variables === undefined ? ['listTeams'] : ['listTeams', variables],
      useFetchData<ListTeamsQuery, ListTeamsQueryVariables>(ListTeamsDocument).bind(null, variables),
      options
    );
export const ListTeamSongsDocument = `
    query listTeamSongs($teamId: String!) {
  listTeamSongs(teamId: $teamId) {
    name
    artist
    id
    name
    defaultKey
    spotifyId
    youtubeId
    originSongId
    emotionTags
    energyTags
    gospelTags
    songTypeTags
    rotationStatus
  }
}
    `;
export const useListTeamSongsQuery = <
      TData = ListTeamSongsQuery,
      TError = unknown
    >(
      variables: ListTeamSongsQueryVariables,
      options?: UseQueryOptions<ListTeamSongsQuery, TError, TData>
    ) =>
    useQuery<ListTeamSongsQuery, TError, TData>(
      ['listTeamSongs', variables],
      useFetchData<ListTeamSongsQuery, ListTeamSongsQueryVariables>(ListTeamSongsDocument).bind(null, variables),
      options
    );
export const GetAllWeightedTeamSongsDocument = `
    mutation getAllWeightedTeamSongs($teamId: String!, $request: GenerateSetlistRequestInput!) {
  getAllWeightedTeamSongs(teamId: $teamId, request: $request) {
    weight
    teamSongId
    teamSong {
      name
      artist
      id
      name
      defaultKey
      spotifyId
      youtubeId
      originSongId
      emotionTags
      energyTags
      gospelTags
      songTypeTags
      rotationStatus
    }
    errors
  }
}
    `;
export const useGetAllWeightedTeamSongsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GetAllWeightedTeamSongsMutation, TError, GetAllWeightedTeamSongsMutationVariables, TContext>) =>
    useMutation<GetAllWeightedTeamSongsMutation, TError, GetAllWeightedTeamSongsMutationVariables, TContext>(
      ['getAllWeightedTeamSongs'],
      useFetchData<GetAllWeightedTeamSongsMutation, GetAllWeightedTeamSongsMutationVariables>(GetAllWeightedTeamSongsDocument),
      options
    );
export const ListAssetsDocument = `
    query listAssets {
  listAssets {
    key
    url
  }
}
    `;
export const useListAssetsQuery = <
      TData = ListAssetsQuery,
      TError = unknown
    >(
      variables?: ListAssetsQueryVariables,
      options?: UseQueryOptions<ListAssetsQuery, TError, TData>
    ) =>
    useQuery<ListAssetsQuery, TError, TData>(
      variables === undefined ? ['listAssets'] : ['listAssets', variables],
      useFetchData<ListAssetsQuery, ListAssetsQueryVariables>(ListAssetsDocument).bind(null, variables),
      options
    );
export const ListContributorsDocument = `
    query listContributors {
  listContributors {
    id
    name
    role
    description
    imageUrl
  }
}
    `;
export const useListContributorsQuery = <
      TData = ListContributorsQuery,
      TError = unknown
    >(
      variables?: ListContributorsQueryVariables,
      options?: UseQueryOptions<ListContributorsQuery, TError, TData>
    ) =>
    useQuery<ListContributorsQuery, TError, TData>(
      variables === undefined ? ['listContributors'] : ['listContributors', variables],
      useFetchData<ListContributorsQuery, ListContributorsQueryVariables>(ListContributorsDocument).bind(null, variables),
      options
    );
export const CreateContributorDocument = `
    mutation createContributor($input: ContributorInput!) {
  createContributor(input: $input) {
    id
    name
  }
}
    `;
export const useCreateContributorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateContributorMutation, TError, CreateContributorMutationVariables, TContext>) =>
    useMutation<CreateContributorMutation, TError, CreateContributorMutationVariables, TContext>(
      ['createContributor'],
      useFetchData<CreateContributorMutation, CreateContributorMutationVariables>(CreateContributorDocument),
      options
    );
export const UpdateContributorDocument = `
    mutation updateContributor($contributorId: String!, $input: ContributorInput!) {
  updateContributor(contributorId: $contributorId, input: $input) {
    id
    name
  }
}
    `;
export const useUpdateContributorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateContributorMutation, TError, UpdateContributorMutationVariables, TContext>) =>
    useMutation<UpdateContributorMutation, TError, UpdateContributorMutationVariables, TContext>(
      ['updateContributor'],
      useFetchData<UpdateContributorMutation, UpdateContributorMutationVariables>(UpdateContributorDocument),
      options
    );
export const DeleteContributorDocument = `
    mutation deleteContributor($contributorId: String!) {
  deleteContributor(contributorId: $contributorId) {
    id
    name
  }
}
    `;
export const useDeleteContributorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteContributorMutation, TError, DeleteContributorMutationVariables, TContext>) =>
    useMutation<DeleteContributorMutation, TError, DeleteContributorMutationVariables, TContext>(
      ['deleteContributor'],
      useFetchData<DeleteContributorMutation, DeleteContributorMutationVariables>(DeleteContributorDocument),
      options
    );
export const GetDashboardDocument = `
    query getDashboard {
  getDashboard {
    id
    videoCollectionIds
    videoCollections {
      id
      name
      onboarding
      videos {
        id
        name
        paid
        description
        duration
        posterUrl
        thumbnailUrl
        url
        contributor {
          name
          imageUrl
        }
      }
    }
  }
}
    `;
export const useGetDashboardQuery = <
      TData = GetDashboardQuery,
      TError = unknown
    >(
      variables?: GetDashboardQueryVariables,
      options?: UseQueryOptions<GetDashboardQuery, TError, TData>
    ) =>
    useQuery<GetDashboardQuery, TError, TData>(
      variables === undefined ? ['getDashboard'] : ['getDashboard', variables],
      useFetchData<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument).bind(null, variables),
      options
    );
export const CreateDashboardDocument = `
    mutation createDashboard($input: DashboardInput!) {
  createDashboard(input: $input) {
    id
  }
}
    `;
export const useCreateDashboardMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateDashboardMutation, TError, CreateDashboardMutationVariables, TContext>) =>
    useMutation<CreateDashboardMutation, TError, CreateDashboardMutationVariables, TContext>(
      ['createDashboard'],
      useFetchData<CreateDashboardMutation, CreateDashboardMutationVariables>(CreateDashboardDocument),
      options
    );
export const UpdateDashboardDocument = `
    mutation updateDashboard($input: DashboardInput!) {
  updateDashboard(input: $input) {
    id
  }
}
    `;
export const useUpdateDashboardMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateDashboardMutation, TError, UpdateDashboardMutationVariables, TContext>) =>
    useMutation<UpdateDashboardMutation, TError, UpdateDashboardMutationVariables, TContext>(
      ['updateDashboard'],
      useFetchData<UpdateDashboardMutation, UpdateDashboardMutationVariables>(UpdateDashboardDocument),
      options
    );
export const GenerateSetlistDocument = `
    mutation generateSetlist($teamId: String!, $request: GenerateSetlistDefinitionInput!) {
  generateSetlist(teamId: $teamId, request: $request) {
    positions {
      name
      artist
      id
      name
      defaultKey
      spotifyId
      youtubeId
      originSongId
      emotionTags
      energyTags
      gospelTags
      songTypeTags
      rotationStatus
    }
  }
}
    `;
export const useGenerateSetlistMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GenerateSetlistMutation, TError, GenerateSetlistMutationVariables, TContext>) =>
    useMutation<GenerateSetlistMutation, TError, GenerateSetlistMutationVariables, TContext>(
      ['generateSetlist'],
      useFetchData<GenerateSetlistMutation, GenerateSetlistMutationVariables>(GenerateSetlistDocument),
      options
    );
export const ListPipelineJobsDocument = `
    query listPipelineJobs($filter: PipelineJobsFilter!) {
  listPipelineJobs(filter: $filter) {
    guid
    workflowStatus
    frameCaptureWidth
    frameCaptureHeight
    duration
    srcVideo
    thumbNailsUrls
    hlsUrl
    dashUrl
    startTime
    endTime
  }
}
    `;
export const useListPipelineJobsQuery = <
      TData = ListPipelineJobsQuery,
      TError = unknown
    >(
      variables: ListPipelineJobsQueryVariables,
      options?: UseQueryOptions<ListPipelineJobsQuery, TError, TData>
    ) =>
    useQuery<ListPipelineJobsQuery, TError, TData>(
      ['listPipelineJobs', variables],
      useFetchData<ListPipelineJobsQuery, ListPipelineJobsQueryVariables>(ListPipelineJobsDocument).bind(null, variables),
      options
    );
export const GetPipelineJobDocument = `
    query getPipelineJob($guid: String!) {
  getPipelineJob(guid: $guid) {
    guid
    workflowStatus
    frameCaptureWidth
    frameCaptureHeight
    duration
    srcVideo
    thumbNailsUrls
    hlsUrl
    dashUrl
    startTime
    endTime
  }
}
    `;
export const useGetPipelineJobQuery = <
      TData = GetPipelineJobQuery,
      TError = unknown
    >(
      variables: GetPipelineJobQueryVariables,
      options?: UseQueryOptions<GetPipelineJobQuery, TError, TData>
    ) =>
    useQuery<GetPipelineJobQuery, TError, TData>(
      ['getPipelineJob', variables],
      useFetchData<GetPipelineJobQuery, GetPipelineJobQueryVariables>(GetPipelineJobDocument).bind(null, variables),
      options
    );
export const ListReelsDocument = `
    query listReels {
  listReels {
    id
    name
    url
    posterUrl
    videoId
  }
}
    `;
export const useListReelsQuery = <
      TData = ListReelsQuery,
      TError = unknown
    >(
      variables?: ListReelsQueryVariables,
      options?: UseQueryOptions<ListReelsQuery, TError, TData>
    ) =>
    useQuery<ListReelsQuery, TError, TData>(
      variables === undefined ? ['listReels'] : ['listReels', variables],
      useFetchData<ListReelsQuery, ListReelsQueryVariables>(ListReelsDocument).bind(null, variables),
      options
    );
export const CreateReelDocument = `
    mutation createReel($input: ReelInput!) {
  createReel(input: $input) {
    id
    name
  }
}
    `;
export const useCreateReelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateReelMutation, TError, CreateReelMutationVariables, TContext>) =>
    useMutation<CreateReelMutation, TError, CreateReelMutationVariables, TContext>(
      ['createReel'],
      useFetchData<CreateReelMutation, CreateReelMutationVariables>(CreateReelDocument),
      options
    );
export const UpdateReelDocument = `
    mutation updateReel($reelId: String!, $input: ReelInput!) {
  updateReel(reelId: $reelId, input: $input) {
    id
    name
  }
}
    `;
export const useUpdateReelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateReelMutation, TError, UpdateReelMutationVariables, TContext>) =>
    useMutation<UpdateReelMutation, TError, UpdateReelMutationVariables, TContext>(
      ['updateReel'],
      useFetchData<UpdateReelMutation, UpdateReelMutationVariables>(UpdateReelDocument),
      options
    );
export const DeleteReelDocument = `
    mutation deleteReel($reelId: String!) {
  deleteReel(reelId: $reelId) {
    id
    name
  }
}
    `;
export const useDeleteReelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteReelMutation, TError, DeleteReelMutationVariables, TContext>) =>
    useMutation<DeleteReelMutation, TError, DeleteReelMutationVariables, TContext>(
      ['deleteReel'],
      useFetchData<DeleteReelMutation, DeleteReelMutationVariables>(DeleteReelDocument),
      options
    );
export const GetSongWeightDocument = `
    query getSongWeight($weightId: String!) {
  getSongWeight(weightId: $weightId) {
    id
    name
    description
    function
    key
    lowValue
    highValue
  }
}
    `;
export const useGetSongWeightQuery = <
      TData = GetSongWeightQuery,
      TError = unknown
    >(
      variables: GetSongWeightQueryVariables,
      options?: UseQueryOptions<GetSongWeightQuery, TError, TData>
    ) =>
    useQuery<GetSongWeightQuery, TError, TData>(
      ['getSongWeight', variables],
      useFetchData<GetSongWeightQuery, GetSongWeightQueryVariables>(GetSongWeightDocument).bind(null, variables),
      options
    );
export const ListSongWeightsDocument = `
    query listSongWeights {
  listSongWeights {
    id
    name
    description
    function
    key
    lowValue
    highValue
  }
}
    `;
export const useListSongWeightsQuery = <
      TData = ListSongWeightsQuery,
      TError = unknown
    >(
      variables?: ListSongWeightsQueryVariables,
      options?: UseQueryOptions<ListSongWeightsQuery, TError, TData>
    ) =>
    useQuery<ListSongWeightsQuery, TError, TData>(
      variables === undefined ? ['listSongWeights'] : ['listSongWeights', variables],
      useFetchData<ListSongWeightsQuery, ListSongWeightsQueryVariables>(ListSongWeightsDocument).bind(null, variables),
      options
    );
export const CreateSongWeightDocument = `
    mutation createSongWeight($input: SongWeightInput!) {
  createSongWeight(input: $input) {
    id
    name
  }
}
    `;
export const useCreateSongWeightMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSongWeightMutation, TError, CreateSongWeightMutationVariables, TContext>) =>
    useMutation<CreateSongWeightMutation, TError, CreateSongWeightMutationVariables, TContext>(
      ['createSongWeight'],
      useFetchData<CreateSongWeightMutation, CreateSongWeightMutationVariables>(CreateSongWeightDocument),
      options
    );
export const UpdateSongWeightDocument = `
    mutation updateSongWeight($weightId: String!, $input: SongWeightInput!) {
  updateSongWeight(weightId: $weightId, input: $input) {
    id
    name
  }
}
    `;
export const useUpdateSongWeightMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSongWeightMutation, TError, UpdateSongWeightMutationVariables, TContext>) =>
    useMutation<UpdateSongWeightMutation, TError, UpdateSongWeightMutationVariables, TContext>(
      ['updateSongWeight'],
      useFetchData<UpdateSongWeightMutation, UpdateSongWeightMutationVariables>(UpdateSongWeightDocument),
      options
    );
export const DeleteSongWeightDocument = `
    mutation deleteSongWeight($weightId: String!) {
  deleteSongWeight(weightId: $weightId) {
    id
    name
  }
}
    `;
export const useDeleteSongWeightMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSongWeightMutation, TError, DeleteSongWeightMutationVariables, TContext>) =>
    useMutation<DeleteSongWeightMutation, TError, DeleteSongWeightMutationVariables, TContext>(
      ['deleteSongWeight'],
      useFetchData<DeleteSongWeightMutation, DeleteSongWeightMutationVariables>(DeleteSongWeightDocument),
      options
    );
export const ListSongsDocument = `
    query ListSongs {
  listSongs {
    appleMusicId
    artist
    artworkUrl
    ccli
    id
    name
    recommendedKey
    recordedKey
    spotifyId
    youtubeId
    emotionTags
    energyTags
    gospelTags
    songTypeTags
    lyrics
  }
}
    `;
export const useListSongsQuery = <
      TData = ListSongsQuery,
      TError = unknown
    >(
      variables?: ListSongsQueryVariables,
      options?: UseQueryOptions<ListSongsQuery, TError, TData>
    ) =>
    useQuery<ListSongsQuery, TError, TData>(
      variables === undefined ? ['ListSongs'] : ['ListSongs', variables],
      useFetchData<ListSongsQuery, ListSongsQueryVariables>(ListSongsDocument).bind(null, variables),
      options
    );
export const CreateSongDocument = `
    mutation createSong($input: SongInput!) {
  createSong(input: $input) {
    id
    name
  }
}
    `;
export const useCreateSongMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSongMutation, TError, CreateSongMutationVariables, TContext>) =>
    useMutation<CreateSongMutation, TError, CreateSongMutationVariables, TContext>(
      ['createSong'],
      useFetchData<CreateSongMutation, CreateSongMutationVariables>(CreateSongDocument),
      options
    );
export const UpdateSongDocument = `
    mutation updateSong($songId: String!, $input: SongInput!) {
  updateSong(songId: $songId, input: $input) {
    id
    name
  }
}
    `;
export const useUpdateSongMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSongMutation, TError, UpdateSongMutationVariables, TContext>) =>
    useMutation<UpdateSongMutation, TError, UpdateSongMutationVariables, TContext>(
      ['updateSong'],
      useFetchData<UpdateSongMutation, UpdateSongMutationVariables>(UpdateSongDocument),
      options
    );
export const DeleteSongDocument = `
    mutation deleteSong($songId: String!) {
  deleteSong(songId: $songId) {
    id
    name
  }
}
    `;
export const useDeleteSongMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSongMutation, TError, DeleteSongMutationVariables, TContext>) =>
    useMutation<DeleteSongMutation, TError, DeleteSongMutationVariables, TContext>(
      ['deleteSong'],
      useFetchData<DeleteSongMutation, DeleteSongMutationVariables>(DeleteSongDocument),
      options
    );
export const ListVideoCollectionsDocument = `
    query listVideoCollections {
  listVideoCollections {
    id
    name
    onboarding
    videoIds
    paid
    hex
    videos {
      id
      name
      paid
      description
      duration
      thumbnailUrl
      url
      contributor {
        name
        imageUrl
      }
    }
  }
}
    `;
export const useListVideoCollectionsQuery = <
      TData = ListVideoCollectionsQuery,
      TError = unknown
    >(
      variables?: ListVideoCollectionsQueryVariables,
      options?: UseQueryOptions<ListVideoCollectionsQuery, TError, TData>
    ) =>
    useQuery<ListVideoCollectionsQuery, TError, TData>(
      variables === undefined ? ['listVideoCollections'] : ['listVideoCollections', variables],
      useFetchData<ListVideoCollectionsQuery, ListVideoCollectionsQueryVariables>(ListVideoCollectionsDocument).bind(null, variables),
      options
    );
export const CreateVideoCollectionDocument = `
    mutation createVideoCollection($input: VideoCollectionInput!) {
  createVideoCollection(input: $input) {
    id
    name
  }
}
    `;
export const useCreateVideoCollectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateVideoCollectionMutation, TError, CreateVideoCollectionMutationVariables, TContext>) =>
    useMutation<CreateVideoCollectionMutation, TError, CreateVideoCollectionMutationVariables, TContext>(
      ['createVideoCollection'],
      useFetchData<CreateVideoCollectionMutation, CreateVideoCollectionMutationVariables>(CreateVideoCollectionDocument),
      options
    );
export const UpdateVideoCollectionDocument = `
    mutation updateVideoCollection($videoCollectionId: String!, $input: VideoCollectionInput!) {
  updateVideoCollection(videoCollectionId: $videoCollectionId, input: $input) {
    id
    name
  }
}
    `;
export const useUpdateVideoCollectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateVideoCollectionMutation, TError, UpdateVideoCollectionMutationVariables, TContext>) =>
    useMutation<UpdateVideoCollectionMutation, TError, UpdateVideoCollectionMutationVariables, TContext>(
      ['updateVideoCollection'],
      useFetchData<UpdateVideoCollectionMutation, UpdateVideoCollectionMutationVariables>(UpdateVideoCollectionDocument),
      options
    );
export const DeleteVideoCollectionDocument = `
    mutation deleteVideoCollection($videoCollectionId: String!) {
  deleteVideoCollection(videoCollectionId: $videoCollectionId) {
    id
  }
}
    `;
export const useDeleteVideoCollectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteVideoCollectionMutation, TError, DeleteVideoCollectionMutationVariables, TContext>) =>
    useMutation<DeleteVideoCollectionMutation, TError, DeleteVideoCollectionMutationVariables, TContext>(
      ['deleteVideoCollection'],
      useFetchData<DeleteVideoCollectionMutation, DeleteVideoCollectionMutationVariables>(DeleteVideoCollectionDocument),
      options
    );
export const ListVideosDocument = `
    query listVideos {
  listVideos {
    id
    name
    paid
    description
    duration
    posterUrl
    thumbnailUrl
    url
    contributorId
    contributor {
      name
      imageUrl
    }
  }
}
    `;
export const useListVideosQuery = <
      TData = ListVideosQuery,
      TError = unknown
    >(
      variables?: ListVideosQueryVariables,
      options?: UseQueryOptions<ListVideosQuery, TError, TData>
    ) =>
    useQuery<ListVideosQuery, TError, TData>(
      variables === undefined ? ['listVideos'] : ['listVideos', variables],
      useFetchData<ListVideosQuery, ListVideosQueryVariables>(ListVideosDocument).bind(null, variables),
      options
    );
export const CreateVideoDocument = `
    mutation createVideo($input: VideoInput!) {
  createVideo(input: $input) {
    id
    name
  }
}
    `;
export const useCreateVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateVideoMutation, TError, CreateVideoMutationVariables, TContext>) =>
    useMutation<CreateVideoMutation, TError, CreateVideoMutationVariables, TContext>(
      ['createVideo'],
      useFetchData<CreateVideoMutation, CreateVideoMutationVariables>(CreateVideoDocument),
      options
    );
export const UpdateVideoDocument = `
    mutation updateVideo($videoId: String!, $input: VideoInput!) {
  updateVideo(videoId: $videoId, input: $input) {
    id
    name
  }
}
    `;
export const useUpdateVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateVideoMutation, TError, UpdateVideoMutationVariables, TContext>) =>
    useMutation<UpdateVideoMutation, TError, UpdateVideoMutationVariables, TContext>(
      ['updateVideo'],
      useFetchData<UpdateVideoMutation, UpdateVideoMutationVariables>(UpdateVideoDocument),
      options
    );
export const DeleteVideoDocument = `
    mutation deleteVideo($videoId: String!) {
  deleteVideo(videoId: $videoId) {
    id
    name
  }
}
    `;
export const useDeleteVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteVideoMutation, TError, DeleteVideoMutationVariables, TContext>) =>
    useMutation<DeleteVideoMutation, TError, DeleteVideoMutationVariables, TContext>(
      ['deleteVideo'],
      useFetchData<DeleteVideoMutation, DeleteVideoMutationVariables>(DeleteVideoDocument),
      options
    );
export const GetMyUserDocument = `
    query getMyUser {
  getMyUser {
    id
    displayName
    email
  }
}
    `;
export const useGetMyUserQuery = <
      TData = GetMyUserQuery,
      TError = unknown
    >(
      variables?: GetMyUserQueryVariables,
      options?: UseQueryOptions<GetMyUserQuery, TError, TData>
    ) =>
    useQuery<GetMyUserQuery, TError, TData>(
      variables === undefined ? ['getMyUser'] : ['getMyUser', variables],
      useFetchData<GetMyUserQuery, GetMyUserQueryVariables>(GetMyUserDocument).bind(null, variables),
      options
    );
export const GetMyPermissionsDocument = `
    query getMyPermissions {
  getMyPermissions
}
    `;
export const useGetMyPermissionsQuery = <
      TData = GetMyPermissionsQuery,
      TError = unknown
    >(
      variables?: GetMyPermissionsQueryVariables,
      options?: UseQueryOptions<GetMyPermissionsQuery, TError, TData>
    ) =>
    useQuery<GetMyPermissionsQuery, TError, TData>(
      variables === undefined ? ['getMyPermissions'] : ['getMyPermissions', variables],
      useFetchData<GetMyPermissionsQuery, GetMyPermissionsQueryVariables>(GetMyPermissionsDocument).bind(null, variables),
      options
    );